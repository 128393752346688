@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap);
* {
  box-sizing: border-box;
}

html {
  line-height: 1.5;
  font-family: Speedee_Reg, Roboto, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  scroll-behavior: smooth;
}

body {
  color: black;
}

a {
  color: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1.25;
  font-family: "Speedee_Bd";
}

@font-face {
  font-family: "Speedee_Reg";
  src: local("Speedee_Reg"), url(../../static/media/Speedee_Rg.d75b8778.eot) format("eot"),
    url(../../static/media/Speedee_Rg.ce55ee0e.ttf) format("ttf"),
    url(../../static/media/Speedee_Rg.04e1910b.woff) format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Speedee_RegCd";
  src: local("Speedee_Reg"), url(../../static/media/SpeedeeCd_Rg.be171f16.eot) format("eot"),
    url(../../static/media/SpeedeeCd_Rg.78fd9936.ttf) format("ttf"),
    url(../../static/media/SpeedeeCd_Rg.ddef242f.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Speedee_Bd";
  src: local("Speedee_Bd"), url(../../static/media/Speedee_Bd.49c0f425.eot) format("eot"),
    url(../../static/media/Speedee_Bd.4a73f7fb.ttf) format("ttf"),
    url(../../static/media/Speedee_Bd.479df42f.woff) format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Speedee_BdIt";
  src: local("Speedee_BdIt"),
    url(../../static/media/Speedee_BdIt.6c422d71.eot) format("eot"),
    url(../../static/media/Speedee_BdIt.1b9843bd.ttf) format("ttf"),
    url(../../static/media/Speedee_BdIt.22b7a892.woff) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: MaxEmoji;
  src: url(../../static/media/Brand-Live-Emoji.757eaf58.otf) format("opentype");
}

.uppercase {
  text-transform: uppercase;
}

