@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap");

* {
  box-sizing: border-box;
}

html {
  line-height: 1.5;
  font-family: Speedee_Reg, Roboto, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  scroll-behavior: smooth;
}

body {
  color: black;
}

a {
  color: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 1.25;
  font-family: "Speedee_Bd";
}

@font-face {
  font-family: "Speedee_Reg";
  src: local("Speedee_Reg"), url("Fonts/Speedee/Speedee_Rg.eot") format("eot"),
    url("Fonts/Speedee/Speedee_Rg.ttf") format("ttf"),
    url("Fonts/Speedee/Speedee_Rg.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Speedee_RegCd";
  src: local("Speedee_Reg"), url("Fonts/Speedee/SpeedeeCd_Rg.eot") format("eot"),
    url("Fonts/Speedee/SpeedeeCd_Rg.ttf") format("ttf"),
    url("Fonts/Speedee/SpeedeeCd_Rg.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Speedee_Bd";
  src: local("Speedee_Bd"), url("Fonts/Speedee/Speedee_Bd.eot") format("eot"),
    url("Fonts/Speedee/Speedee_Bd.ttf") format("ttf"),
    url("Fonts/Speedee/Speedee_Bd.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Speedee_BdIt";
  src: local("Speedee_BdIt"),
    url("Fonts/Speedee/Speedee_BdIt.eot") format("eot"),
    url("Fonts/Speedee/Speedee_BdIt.ttf") format("ttf"),
    url("Fonts/Speedee/Speedee_BdIt.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: MaxEmoji;
  src: url("Fonts/Emoji/Brand-Live-Emoji.otf") format("opentype");
}

.uppercase {
  text-transform: uppercase;
}
